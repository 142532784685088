import React from 'react'
import SignInForm from '../components/SignIn/SignIn'

const SignInPage = () => (
  <div>
    <h1>Sign In</h1>
    <SignInForm />
  </div>
)

export default SignInPage
